@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

@import "../../../style/Colors.scss";

.sideMenuContent {
  .sideMenuTitle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    height: 40px;
    width: 40px;
    fill: #fff;
  }

  .sideMenuText {
    font-size: 1.8em;
  }

  .sideMenuSubtitle {
    text-align: center;
    opacity: 0.5;

    & + ul {
      border-top: solid 1px rgba(255, 255, 255, 0.1);
    }
  }
}
