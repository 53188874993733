@import "./Colors.scss";

/*************************** BOOTSTRAP THEME *************************/
$theme-colors: (
  "primary": $primaryColor,
  "danger": $dangerColor,
  "secondary": $secondaryColor,
  "warning": $warningColor,
  "success": $successColor,
);

@import "~bootstrap/scss/bootstrap";

.btn-light,
.btn-outline-light {
  color: $mainTextColor;
}

.btn-secondary,
.btn-warning {
  &,
  &:hover {
    color: #fff;
  }

  &:active {
    color: darken($secondaryColor, 35%) !important;
  }
}

.btn-block + .btn-block {
  margin-top: 0;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav {
  &.nav-tabs {
    border-color: lighten($primaryColor, 10);

    .nav-item {
      .nav-link {
        border: solid 1px transparent;
        cursor: pointer;
        border-radius: 0;

        &:not(.active) {
          &:hover {
            border: solid 1px $primaryColor;
            color: $primaryColor;
          }
        }

        &.active {
          background: $primaryColor;
          color: #fff;
        }
      }
    }
  }
}

.badge {
  &.badge-secondary {
    color: $mainTextColor;
  }
}

/*************************** APP THEME *************************/
@font-face {
  font-family: "RobotoLight";
  src: url("../fonts/Roboto-Light.ttf");
}

body {
  background: $mainBackgroundColor;
  color: $mainTextColor;
  font-family: "RobotoLight";

  label {
    color: $darkTextColor;
    font-weight: bold;
    margin: 0;
  }

  a,
  .btn-link {
    &,
    &:hover,
    &:active {
      color: inherit;
      text-decoration: none;
    }
  }
}

.invalid .invalid-feedback {
  display: block !important;
}

.table td.width-auto,
.table th.width-auto {
  width: 1px;
  white-space: nowrap;
}

// Droppable
.droppable {
  background: transparent;
  padding: 5px;

  &.dragging {
    background: lighten($primaryColor, 25%);
  }

  > * {
    margin: 2px 0;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .item {
    border: dotted 2px #ccc;
    padding: 5px 10px;
    background-color: #fff;
    &:hover {
      border: dashed 2px lighten($primaryColor, 25%);
    }
  }
}

form label {
  color: $mainTextColor;
}

form .required > label::after {
  content: " *";
  font-weight: bold;
  font-size: 16px;
}

fieldset legend {
  border-bottom: 1px solid #ccc;
}

.tab-content {
  padding-top: 1rem;
}

.whiteSpacePre {
  white-space: pre-wrap;
}

textarea {
  min-height: 100px;
}

// Font sizes
.textXL {
  font-size: 1.2rem;
}

// Rotations
.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-135 {
  transform: rotate(135deg);
}

img {
  &.thumbnail {
    max-width: 100px;
    max-height: 100px;
  }

  &.thumbnail-s {
    max-width: 75px;
    max-height: 75px;
  }

  &.thumbnail-xs {
    max-width: 50px;
    max-height: 50px;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
